<template>
    <Template
        :people="people"
        @onClickItem="
            (item) =>
                $router.push({
                    name: 'Ecosystem People Detail',
                    params: { companyId: company?.id, id: item?.id?.id },
                })
        "
        :emptyViewBinding="emptyViewBinding"
        :searchText="searchText"
        :onSearchTextChange="onSearchTextChange"
        :toggleSlideover="
            () => {
                isShow = true;
            }
        "
        @onSort="onSort"
        @onFilter="onFilter"
        @onExport="onExport"
    />
    <InvitePeople
        v-bind="{
            ...c.invitePeople,
            isShow: isShow,
            isLoading,
            url: inviteLink,
        }"
        @onClose="() => (isShow = false)"
        @onSubmit="(item) => onSendInvite(item)"
    />
</template>

<script>
import Template from "../components/templates/EcosystemPeople/EcosystemPeople";
import InvitePeople from "@/components/organisms/Modals/InvitePeople/InvitePeople";
import { c } from "../components/constants";
import moment from "moment";
import { Settings } from "../../settings";
import { json } from "overmind";
import { upperFirst } from "lodash";

export default {
    components: {
        Template,
        InvitePeople,
    },
    data() {
        return {
            c,
            isShow: false,
            people: [],
            company: null,
            emptyViewBinding: {
                description: "No people to display just yet!",
            },
            searchText: "",
            inviteLink: `https://${Settings.mainDomain}/#/register-user?eco=${this.$route.params?.companyId}`,
            isLoading: false,
        };
    },
    watch: {
        sort: {
            handler(val) {
                this.peopleList();
            },
            deep: true,
        },
        searchText: function (val) {
            this.peopleList();
        },
    },
    async created() {
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: 'companyDefault'
            });
            console.log(companies);
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
            console.log(this.company, "company");
        }
        const people = [];
        this.company?.ventures?.map((v) =>
            v?.users?.map((u) => {
                if (!people?.find((p) => p?.id?.id === u?.id)) {
                    people?.push({
                        Name:
                            upperFirst(u?.firstName) +
                            " " +
                            upperFirst(u?.lastName),
                        Email: u?.email,
                        Team: upperFirst(v?.name),
                        "Date Joined": moment(u?.createdAt).format("M/D/YY"),
                        id: {
                            ...u,
                            venture: v,
                        },
                    });
                }
            })
        );
        this.company?.programs?.map((p) =>
            p?.cohorts?.map((c) =>
                c?.ventures?.map((v) =>
                    v?.users?.map((u) => {
                        if (!people?.find((p) => p?.id?.id === u?.id)) {
                            people?.push({
                                Name:
                                    upperFirst(u?.firstName) +
                                    " " +
                                    upperFirst(u?.lastName),
                                Email: u?.email,
                                Team: upperFirst(v?.name),
                                "Date Joined": moment(u?.createdAt).format(
                                    "M/D/YY"
                                ),
                                id: {
                                    ...u,
                                    venture: v,
                                },
                            });
                        }
                    })
                )
            )
        );

        this.people = people;
        console.log(this.company, "length");
        this.origPeople = [...json(this.people)];
    },
    methods: {
        peopleList() {
            let items = [...json(this.origPeople)];
            console.log(this.sort, this.filter, "items ===========");
            if (this.searchText.trim()) {
                items = items.filter((item) =>
                    item?.Name.toLowerCase().includes(
                        this.searchText.trim().toLowerCase()
                    )
                );
            }
            if (this.sort?.parent?.name === "First Name") {
                items = items.sort((a, b) =>
                    a?.id?.firstName > b?.id?.firstName ? 1 : -1
                );
            } else if (this.sort?.parent?.name === "Last Name") {
                items = items.sort((a, b) =>
                    a?.id?.lastName > b?.id?.lastName ? 1 : -1
                );
            } else if (this.sort?.parent?.name === "Email") {
                items = items.sort((a, b) =>
                    a?.id?.email > b?.id?.email ? 1 : -1
                );
            } else if (this.sort?.parent?.name === "Team") {
                items = items.sort((a, b) =>
                    a?.id?.venture?.name > b?.id?.venture?.name ? 1 : -1
                );
            } else if (this.sort?.parent?.name === "Date Joined") {
                items = items.sort((a, b) =>
                    a?.id?.createdAt > b?.id?.createdAt ? -1 : 1
                );
            }
            this.people = items;
        },
        async onSendInvite(item) {
            if (this.isLoading) return false;
            const emails = item[0]?.value;
            if (emails) {
                try {
                    this.isLoading = true;
                    const items = emails?.split(",");
                    const invites = [];
                    items.map((i) => {
                        invites.push({
                            email: i?.trim(),
                        });
                    });
                    await this.actions.user.inviteContact({
                        contacts: invites,
                        userId: this.state.currentUser?.id,
                        extraData: {
                            inviteLink: this.inviteLink,
                            ecosystemId: this.$route.params?.companyId,
                            ecosystemName: this.company?.name,
                            originPath: `https://${Settings.mainDomain}/#/${this.$route.fullPath}`,
                            username: this.state.currentUser?.username,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                        },
                    });
                    this.actions.alert.showSuccess({
                        message: "Sent invitations successfully!",
                    });
                    this.isShow = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        onSearchTextChange(searchText) {
            this.searchText = searchText;
        },
        arrayToCsv(data) {
            const array = [Object.keys(data[0])].concat(data);

            return array
                .map((it) => {
                    return Object.values(it).toString();
                })
                .join("\n");
        },

        /* https://stackoverflow.com/a/68146412 */
        /* downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;')*/
        downloadBlob(content, filename, contentType) {
            // Create a blob
            var blob = new Blob([content], { type: contentType });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement("a");
            pom.href = url;
            pom.setAttribute("download", filename);
            pom.click();
        },
        onExport() {
            console.log(this.people, "people");
            const people = [];
            this.people.map((p) =>
                people.push({
                    Name: p?.Name,
                    Email: p?.Email,
                    Team: p?.Team,
                    "Date Joined": p?.["Date Joined"],
                })
            );
            const myLogs = this.arrayToCsv(people);
            this.downloadBlob(
                myLogs,
                "./people.csv",
                "text/csv;charset=utf-8;"
            );
        },
        onSort(sort) {
            this.sort = sort;
            console.log(this.sort, "sort");
            this.peopleList();
        },
        onFilter(filter) {
            this.filter = filter;
            console.log(this.filter, "filter");
        },
    },
};
</script>

<style scoped></style>
